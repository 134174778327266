(function() {
	const countdownTo = new Date(Date.UTC(2023, 2, 6));
	console.log(countdownTo)
	setInterval(function() {
		let now = new Date();
		let diff = Math.floor((countdownTo - now) / 1000);
		let days = Math.floor(diff / (3600 * 24));
		let hours = Math.floor((diff % (3600 * 24)) / 3600);
		let minutes = Math.floor((diff % 3600) / 60);
		let seconds = diff % 60;

		let daysStr = days.toString().padStart(2, '0');
		let hoursStr = hours.toString().padStart(2, '0');
		let minutesStr = minutes.toString().padStart(2, '0');
		let secondsStr = seconds.toString().padStart(2, '0');

		document.getElementById("days").innerText = `${daysStr}`;
		document.getElementById("hours").innerText = `${hoursStr}`;
		document.getElementById("minutes").innerText = `${minutesStr}`;
		document.getElementById("seconds").innerText = `${secondsStr}`;

	}, 100);
})();